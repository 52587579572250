import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

import {
  fetchWorkspaceClients,
  addClient as addClientService,
  updateClient as updateClientService,
} from '../../services/client';
import { RootState } from '../../store';
import { showSnackbar } from './alertSlice';
import { logout } from '../onboarding/loginSlice';

export interface IClient {
  id: number;
  name: string;
  workspaceId?: number;
  favourite: boolean;
  jobs?: number;
  totalApplications?: number;
}

export interface IClientState {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
  clients: Array<IClient>;
  selectedClient?: IClient;
}

const initialState: IClientState = {
  loading: 'idle',
  error: '',
  clients: [],
};

export const getClients = createAsyncThunk(
  'dashboard/clients',
  async (_args, { getState }) => {
    const state = getState() as RootState;
    //@ts-ignore
    const currentWorkspace = state.app.workspace.slug;
    const response = await fetchWorkspaceClients(currentWorkspace);
    return response.data;
  }
);

export const addClient = createAsyncThunk(
  'dashboard/addClients',
  async (data: object, { getState, dispatch, rejectWithValue }) => {
    try {
      const state: any = getState();
      const currentWorkspace = state.app.workspace;
      const response = await addClientService({
        ...data,
        workspaceId: currentWorkspace.id,
      });
      dispatch(getClients());
      return response.data;
    } catch (error) {
      //@ts-ignore
      const errorMessage =
        //@ts-ignore
        error?.response?.data?.message ||
        'Something went wrong! Please try again!';
      dispatch(
        showSnackbar({
          open: true,
          severity: 'error',
          message: errorMessage,
        })
      );
      //@ts-ignore
      if (!error.response) {
        throw error;
      }
      //@ts-ignore
      rejectWithValue(error.response.data);
    }
  }
);

export const updateClient = createAsyncThunk(
  'dashboard/updateClients',
  async (payload: any, { getState, dispatch, rejectWithValue }) => {
    try {
      const state: any = getState();
      const currentWorkspace = state.app.workspace;
      await updateClientService(
        {
          ...payload.data,
          workspaceId: currentWorkspace.id,
        },
        payload.channelId
      );
      dispatch(getClients());
      return;
    } catch (error) {
      //@ts-ignore
      const errorMessage =
        //@ts-ignore
        error?.response?.data?.message ||
        'Something went wrong! Please try again!';
      dispatch(
        showSnackbar({
          open: true,
          severity: 'error',
          message: errorMessage,
        })
      );
      //@ts-ignore
      if (!error.response) {
        throw error;
      }
      //@ts-ignore
      rejectWithValue(error.response.data);
    }
  }
);

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    selectClient: (state, action) => {
      try {
        const newChannel = action.payload;
        //Check channel is available in active workspace
        const isChannelExist = state.clients.find(
          (client) => client.id === newChannel?.id
        );
        const selectedChannel = isChannelExist ? newChannel : state.clients[0];
        localStorage.setItem('baseChannel', JSON.stringify(selectedChannel));
        state.selectedClient = selectedChannel;
      } catch (error) {
        localStorage.setItem('baseChannel', JSON.stringify(action.payload));
        state.selectedClient = action.payload;
      }
    },
    setClientOnSession: (state, action) => {
      localStorage.setItem('baseChannel', JSON.stringify(action.payload));
      state.selectedClient = action.payload;
    },
    favouriteChannel: (state, action) => {
      state.clients = state.clients.map((client) =>
        client.id === action.payload
          ? { ...client, favourite: !client.favourite }
          : client
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state) => {
        return {
          ...state,
          loading: 'pending',
        };
      })
      .addCase(getClients.fulfilled, (state, action) => {
        return {
          ...state,
          clients: action.payload,
          loading: 'succeeded',
        };
      })
      .addCase(getClients.rejected, (state, action) => {
        return {
          ...state,
          loading: 'failed',
        };
      })
      .addCase(addClient.pending, (state) => {
        return {
          ...state,
          loading: 'pending',
        };
      })
      .addCase(addClient.fulfilled, (state) => {
        return {
          ...state,
          loading: 'succeeded',
        };
      })
      .addCase(addClient.rejected, (state) => {
        return {
          ...state,
          loading: 'failed',
        };
      })
      .addCase(logout, (state) => {
        state.loading = 'idle';
        state.error = '';
        state.clients = [];
        state.selectedClient = {
          id: 0,
          name: '',
          favourite: false,
        };
      });
  },
});

export const { selectClient, favouriteChannel, setClientOnSession } =
  clientSlice.actions;

export default clientSlice.reducer;

export const getUserWorkspaceClients = createSelector(
  [(state: RootState) => state.client],
  (client) => {
    const clients = client.clients;
    return [...clients].sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const getClientOptions = (state: RootState) => {
  const { loading, clients } = state.client;
  if (loading === 'succeeded') {
    return clients.map((client) => ({
      label: client.name,
      value: client.id,
    }));
  }
  return [];
};

export const getFavouriteClients = createSelector(
  [(state: RootState) => state.client],
  (client) => {
    const { loading, clients } = client;
    if (loading === 'succeeded') {
      return clients.filter((client) => client.favourite);
    }
    return [];
  }
);
