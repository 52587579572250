import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import DashboardAPI from '../../services/dashboard.service';

interface IOfferAndJoined {
  offer: number;
  joined: number;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
}

const fetchOfferAndJoined = createAsyncThunk(
  'cv-sourced/offer-joined',
  async (payload: any) => {
    const { workspaceId = '', start = '', end = '' } = payload;
    return await DashboardAPI.fetchOfferAndJoined(workspaceId, start, end);
  }
);

const initialState: IOfferAndJoined = {
  offer: 0,
  joined: 0,
  loading: 'idle',
  error: '',
};

const offerAndJoinedSlice = createSlice({
  name: 'cvSource',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchOfferAndJoined.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchOfferAndJoined.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.offer = action.payload.offer;
        state.joined = action.payload.joined;
      })
      .addCase(fetchOfferAndJoined.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'something went wrong!';
      }),
});

export default offerAndJoinedSlice.reducer;

export { fetchOfferAndJoined };
