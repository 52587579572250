import { useState } from 'react';
import {
  Box,
  TextField,
  FormControl,
  Button,
  Typography,
  Stack,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Paper,
} from '@mui/material';
import { Formik } from 'formik';

import UploadResume from './UploadResume.react';
import { useAppDispatch } from '../../hooks/redux';
import { showSnackbar } from '../../redux/features/dashboard/alertSlice';

import TalentPoolAPI from '../../redux/services/talent-pool.service';
import { axiosInstance } from '../../utils/axios.instance';
import ResumeViewer from '../resume-viewer';

interface IForm {
  errors: string;
  file: any; // Check about it to be converted to blog type
  email: string;
  firstName: string;
  phone: string;
  location?: string;
  designation?: string;
}

const defaultValues: IForm = {
  errors: '',
  file: {},
  email: '',
  firstName: '',
  phone: '',
  location: '',
  designation: '',
};

interface Props {
  job: {
    title?: string;
    id?: string;
  };
}
const AddSingleCandidate = (props: Props) => {
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState(defaultValues);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  const resetEverything = (resetForm: any) => {
    setFormValues({
      errors: '',
      file: {},
      firstName: '',
      email: '',
      phone: '',
    });

    if (resetForm) {
      resetForm();
    }
  };

  const convertToPDF = (formData: any) => {
    axiosInstance({
      method: 'POST',
      url: '/api/v1/job-application/convert-pdf',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    })
      .then((response) => {
        //setPdfData(response.data);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url: any = URL.createObjectURL(blob);
        setPdfData(url);
      })
      .catch((error) => {
        console.log('error', error);
        setPdfData(null);
      });
  };

  const handleFileUpload = (file: any) => {
    setFormValues({
      ...formValues,
      file,
    });

    const formData = new FormData();
    formData.append('file', file);
    convertToPDF(formData);
    setIsFileUploading(true);
    axiosInstance({
      method: 'POST',
      url: '/api/v1/job-application/extract-file',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        const { email, mobile } = data;
        setFormValues({
          ...formValues,
          file,
          email,
          phone: mobile,
        });
        setIsFileUploading(false);
      })
      .catch((err) => {
        const { response: { data = '' } = {} } = err;
        dispatch(
          showSnackbar({
            severity: 'error',
            message: 'Something went wrong',
            open: true,
          })
        );
        setIsFileUploading(false);
      });
  };

  const onFormSubmit = (values: any, setSubmitting: any, resetForm: any) => {
    const formData = new FormData();
    formData.append('name', values.firstName + ' ' + values.lastName);
    formData.append('email', values.email.trim());
    formData.append('mobile', values.phone);
    formData.append('remark', values.remark);
    formData.append('resume', formValues.file);
    formData.append('jobId', props.job.id || '');
    formData.append('location', values.location || '');
    formData.append('designation', values.designation || '');
    setSubmitting(true);
    if (props.job.id) {
      // TODO: Refactor below code to make a Utility
      axiosInstance.defaults.headers['content-type'] = 'multipart/form-data';
      axiosInstance
        .post('/api/v2/job-application/add-applicant', formData)
        .then(({ data }) => {
          dispatch(
            showSnackbar({
              severity: 'success',
              message: data.message,
              open: true,
            })
          );
          setSubmitting(false);
          resetEverything(resetForm);
        })
        .catch((err) => {
          if (err.response) {
            const message =
              err?.response?.data?.message ||
              'Something went wrong while creating candidate';
            dispatch(
              showSnackbar({
                severity: 'error',
                message,
                open: true,
              })
            );
          } else {
            dispatch(
              showSnackbar({
                severity: 'error',
                message: 'Unknown error has occurred, please try again later',
                open: true,
              })
            );
          }
          setSubmitting(false);
          // resetEverything(resetForm);
        });
      axiosInstance.defaults.headers['content-type'] = 'application/json';
    } else {
      TalentPoolAPI.uploadSingleCandidateRequest(formData)
        .then(({ data }) => {
          dispatch(
            showSnackbar({
              severity: 'success',
              message: data,
              open: true,
            })
          );
          setSubmitting(false);
          resetEverything(resetForm);
        })
        .catch((error) => {
          if (error.response) {
            const message =
              error?.response?.data?.message ||
              'Something went wrong while creating candidate';
            dispatch(
              showSnackbar({
                severity: 'error',
                message,
                open: true,
              })
            );
          } else {
            dispatch(
              showSnackbar({
                severity: 'error',
                message: 'Unknown error has occurred, please try again later',
                open: true,
              })
            );
          }
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <Backdrop open={isFileUploading} style={{ zIndex: 9999 }}>
        <CircularProgress />
      </Backdrop>
      <Box
        component={Paper}
        borderRadius={0}
        p={1}
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1.5fr', md: '1fr 1.5fr' },
          gap: 1,
        }}
      >
        <Box>
          <Formik
            enableReinitialize={true}
            initialValues={{
              ...formValues,
              lastName: '',
              firstName: '',
              designation: '',
              location: '',
              remark: '',
            }}
            validate={(values) => {
              let errors: any = {};

              if (!values.firstName) {
                errors.firstName = 'First Name is required';
              } else if (values.firstName.length < 3) {
                errors.firstName =
                  'First Name should have a minimum length of 3';
              }

              if (!values.email) {
                errors.email = 'email is required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                  values.email.trim()
                )
              ) {
                errors.email = 'email is invalid!';
              }
              if (values.phone === '') {
                errors.phone = 'Phone number is required';
              } else if (values.phone && /[a-zA-Z]/.test(values.phone)) {
                errors.phone = 'Phone number should contain only numeric';
              } else if (values.phone.length !== 10) {
                let phone =
                  values.phone.indexOf('+') === 0
                    ? values.phone.substr(1)
                    : values.phone;
                if (/^(91\-|91|91\s|)[0-9]{10}$/g.test(phone)) {
                  console.log('Valid phone');
                } else {
                  errors.phone = 'Phone number is invalid';
                }
              }

              if (!formValues.file.name) {
                errors.file = 'File is required';
              }

              if (values.remark && values.remark.trim().length > 250) {
                errors.remark = 'Remark should be of 250 characters only';
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              onFormSubmit(values, setSubmitting, resetForm);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  component="form"
                  onSubmit={handleSubmit}
                >
                  {props?.job?.title && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="h6">{props.job.title}</Typography>
                    </Stack>
                  )}

                  <UploadResume
                    handleFileUpload={handleFileUpload}
                    isSubmitting={isFileUploading}
                    filename={formValues.file ? formValues.file.name : ''}
                  />
                  <Typography variant="body1">Candidate Details</Typography>
                  <Typography variant="caption">
                    Upload a resume above to auto-fill these details
                  </Typography>
                  <Box>
                    <Stack direction="row" spacing={1}>
                      <FormControl>
                        <TextField
                          label="First Name"
                          placeholder="Please enter candidate first name"
                          value={values.firstName}
                          size="small"
                          margin="normal"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.firstName}
                          helperText={
                            touched.firstName && errors && errors.firstName
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          label="Last Name"
                          placeholder="Please enter candidate last name"
                          value={values.lastName}
                          size="small"
                          margin="normal"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.lastName}
                          helperText={
                            touched.lastName && errors && errors.lastName
                          }
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <FormControl>
                        <TextField
                          label="Email"
                          placeholder="Please enter candidate email address"
                          value={values.email}
                          size="small"
                          margin="normal"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.email}
                          helperText={touched.email && errors && errors.email}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          label="Phone no"
                          placeholder="Please enter candidate email address"
                          value={values.phone}
                          size="small"
                          margin="normal"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.phone}
                          helperText={touched.phone && errors && errors.phone}
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <FormControl>
                        <TextField
                          label="Designation"
                          placeholder="Current Designation(optional)"
                          value={values.designation}
                          size="small"
                          margin="normal"
                          name="designation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          label="Location"
                          placeholder="Current location of candidate(optional)"
                          value={values.location}
                          size="small"
                          margin="normal"
                          name="location"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormControl>
                    </Stack>
                  </Box>

                  <FormControl>
                    <TextField
                      label="Remark(Optional)"
                      placeholder="Please enter candidate email address"
                      value={values.remark}
                      size="small"
                      margin="normal"
                      name="remark"
                      rows={3}
                      multiline
                      maxRows={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.remark}
                      helperText={touched.remark && errors && errors.remark}
                    />
                  </FormControl>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Create Candidate
                    </Button>
                    {formValues.errors.length ? (
                      <FormHelperText error>{formValues.errors}</FormHelperText>
                    ) : (
                      <noscript />
                    )}
                  </Box>
                </Box>
              );
            }}
          />
        </Box>
        <Box width="100%" pt={2}>
          <ResumeViewer fileUrl={pdfData} />
        </Box>
      </Box>
    </>
  );
};

export default AddSingleCandidate;
