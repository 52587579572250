import { lazy } from 'react';

// core components
import DashboardLayout from '../components/layout/dashboard-layout';
import DashboardPage from '../pages/Dashboard.page';
import SuspenseView from '../components/SuspenseView.react';

//TODO: view component should be moved to view folder
import AddCandidate from '../components/AddCandidate';
import JobApplicants from '../components/JobApplicants';
import CandidateDetailsView from '../components/candidate-details';
import EventCalendar from '../components/event-calendar';
// End of TODO

const TalentPoolPage = lazy(() => import('../pages/talent-pool'));
const Reports = lazy(() => import('../pages/reports/Report.page'));
const JobPostingPage = lazy(() => import('../pages/JobPosting.page'));
const EditJobPage = lazy(() => import('../pages/EditJob.page'));
const NotificationPage = lazy(() => import('../pages/Notification.page.react'));
const AddJob = lazy(() => import('../pages/AddJob.page'));
const RecruiterInboxPage = lazy(() => import('../pages/RecruiterInbox.page'));
const WorkspaceJobs = lazy(() => import('../pages/WorkspaceJobs.page'));
const Candidates = lazy(() => import('../pages/Candidates.page'));
const RecentActivity = lazy(() => import('../pages/RecentActivity.page'));
const ChannelsPage = lazy(() => import('../pages/channels'));
const DayPlanner = lazy(() => import('../pages/day-planner/DayPlanner'));

// Admin accessiable page(s)
const RecruiterDayTODO = lazy(() => import('../pages/recruiter/DayToDo'));
const ReportDetailView = lazy(
  () => import('../pages/reports/ReportDetailView.page')
);
const RecruiterProfile = lazy(() => import('../pages/recruiter/Profile'));

const WorkspaceRoutes = {
  path: '/',
  element: <DashboardLayout />,
  children: [
    {
      path: 'dashboard',
      element: <DashboardPage />,
    },
    {
      path: 'workspace',
      element: <DashboardPage />,
    },
    {
      path: 'dashboard/recruiter-report',
      element: (
        <SuspenseView>
          <Reports />
        </SuspenseView>
      ),
    },
    {
      path: 'dashboard/recruiter-report/day-to-do/:recruiterId',
      element: (
        <SuspenseView>
          <RecruiterDayTODO />
        </SuspenseView>
      ),
    },
    {
      path: 'dashboard/recruiter-report/details/:recruiterId',
      element: (
        <SuspenseView>
          <ReportDetailView />
        </SuspenseView>
      ),
    },
    {
      path: 'dashboard/recruiter-report/profile/:recruiterId',
      element: (
        <SuspenseView>
          <RecruiterProfile />
        </SuspenseView>
      ),
    },
    {
      path: 'workspace/jobs',
      element: (
        <SuspenseView>
          <WorkspaceJobs />
        </SuspenseView>
      ),
    },
    {
      path: 'workspace/day-plan',
      element: (
        <SuspenseView>
          <DayPlanner />
        </SuspenseView>
      ),
    },
    {
      path: 'channels',
      element: (
        <SuspenseView>
          <ChannelsPage />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/postings',
      element: (
        <SuspenseView>
          <RecruiterInboxPage />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/postings/:id',
      element: (
        <SuspenseView>
          <JobPostingPage />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/postings/create',
      element: (
        <SuspenseView>
          <AddJob />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/postings/job',
      element: (
        <SuspenseView>
          <EditJobPage />
        </SuspenseView>
      ),
    },
    {
      path: 'channels/postings/add-candidate',
      element: <AddCandidate />,
    },
    {
      path: 'channels/applicants',
      element: (
        <SuspenseView>
          <Candidates />
        </SuspenseView>
      ),
    },
    {
      path: 'applicants/:jhash',
      element: <JobApplicants />,
    },
    {
      path: 'applicant',
      element: <CandidateDetailsView />,
    },
    {
      path: 'calendar',
      element: <EventCalendar />,
    },
    {
      path: 'notifications',
      element: (
        <SuspenseView>
          <NotificationPage />
        </SuspenseView>
      ),
    },
    {
      path: 'candidates/talent-pool',
      element: (
        <SuspenseView>
          <TalentPoolPage />
        </SuspenseView>
      ),
    },

    {
      path: 'recent-activities',
      element: (
        <SuspenseView>
          <RecentActivity />
        </SuspenseView>
      ),
    },
  ],
};

export default WorkspaceRoutes;
