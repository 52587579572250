import dayjs from 'dayjs';
import { groupBy, keys, some, isNaN } from 'lodash';
import CryptoJS from 'crypto-js';

export const formatNumberToK = (num: number) => {
  return num >= 1000 ? (num / 1000).toFixed(2) + 'K' : num.toString();
};

export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 B';

  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  if (bytes < kilobyte) return bytes + ' B';
  else if (bytes < megabyte) return (bytes / kilobyte).toFixed(2) + ' KB';
  else return (bytes / megabyte).toFixed(2) + ' MB';
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isValidDomain = (domain: string) => {
  return /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(domain);
};

export const stringToColor = (string: string) => {
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name: string) => {
  const nameArray = name.split(' ').filter(Boolean);
  let avatarName = 'XL';
  if (nameArray.length >= 2) {
    avatarName = `${nameArray[0][0]}${nameArray[1][0]}`;
  } else {
    avatarName = `${nameArray[0][0]}`;
  }
  return {
    children: avatarName,
  };
};

export const capitalFirst = (input: string) => {
  if (!input) {
    return '';
  }
  let value = input.toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isOrderChangedForObjectArray = (
  arr: Array<any>,
  property: string
) => {
  const sortedArr = [...arr].sort((a, b) => a[property] - b[property]);
  return !arr.every(
    (obj, index) => obj[property] === sortedArr[index][property]
  );
};

export const generateTable = (feedbacks: Array<any>, name?: string) => {
  const groupByFeedback = groupBy(feedbacks, (feedback) => feedback.recordId);
  const feedbackRows: any = [];

  // Initialize headers with Candidate column
  const headers: any = [{ header: 'Candidate', key: 'Candidate' }];

  // Iterate over each group and construct rows
  for (const key in groupByFeedback) {
    if (Object.hasOwn(groupByFeedback, key)) {
      const group = groupByFeedback[key];
      const row: any = { Candidate: name || '' };

      group.forEach((field) => {
        const headerObj: any = { header: field.label, key: field.label };

        // Add header if not already present
        if (!headers.some((h: any) => h.header === field.label)) {
          headers.push(headerObj);
        }

        row[field.label] = field.value;
      });

      feedbackRows.push(row);
    }
  }

  // Construct header row
  const headerCols = headers
    .map(({ header }: any) => `<th>${header}</th>`)
    .join('');

  const headRow = `<tr>${headerCols}</tr>`;

  // Construct data rows
  const rows = feedbackRows
    .map(
      (row: any) =>
        `<tr>${Object.values(row)
          .map(
            (val) =>
              `<td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${val}</td>`
          )
          .join('')}</tr>`
    )
    .join('');

  // Construct HTML table
  const htmlTable = `<table style="border-collapse: collapse; width: 100%;">${headRow}${rows}</table>`;

  return htmlTable;
};

export const generateTableRowAndHeader = (
  data: Array<{}>,
  headersMapping?: any
) => {
  const keys = Object.keys(data[0]);
  const headers = keys.map((key) =>
    headersMapping
      ? headersMapping[key] || capitalFirst(key)
      : capitalFirst(key)
  );
  const rows = data.map((item) => Object.values(item));

  return {
    headers,
    rows,
  };
};

const isDateString = (str: string) => {
  const parsedDate = Date.parse(str);
  return !isNaN(parsedDate) && !isNaN(new Date(parsedDate));
};

export const getObjectValueByAccessor = (object: any, accessor: string) => {
  const keys = accessor.split('.');
  let value = object;
  for (const key of keys) {
    if (value === null || value === undefined) {
      return null;
    }
    value = value[key];
  }

  if (isDateString(value)) {
    return dayjs(value).format('MMM DD, YYYY');
  } else {
    return value;
  }
};

export const toBase64 = (str: string) => {
  return btoa(str);
};

export const encodeToBase64 = (data: object | string) => {
  const jsonString = typeof data === 'object' ? JSON.stringify(data) : data;
  return btoa(jsonString);
};

export const decodeFromBase64 = (encodedData: string) => {
  const decodedString = atob(encodedData);
  try {
    return JSON.parse(decodedString);
  } catch (e) {
    return decodedString;
  }
};

export const getNameFromDomain = (input: string) => {
  const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)\.[a-z]{2,}$/;
  const match = input.match(domainRegex);
  if (match) {
    return match[1];
  }
  return input;
};

export const filterMenuItemsOnPermissions = (
  permissions: Record<string, string>,
  navItemsGroup: any
) => {
  navItemsGroup.children = navItemsGroup.children.filter((item: any) => {
    if (item.acl && item.acl.length) {
      return item.acl.every((item: any) => permissions[item]);
    }
    return true;
  });
  return navItemsGroup;
};

export const decipherData = (cipherText: any) => {
  try {
    const decipherText = CryptoJS.AES.decrypt(cipherText, 'HireXL');
    console.log('decryptedString', decipherText);
    const decryptedString = decipherText.toString(CryptoJS.enc.Utf8);
    console.log('decryptedString', decryptedString);
    return JSON.parse(decryptedString);
  } catch (error) {
    console.log('error', error);
    return {};
  }
};
