import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import DashboardAPI from '../../services/dashboard.service';

interface ICVSourced {
  total: number;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
}

const fetchTotalCV = createAsyncThunk(
  'cv-sourced/total-cv',
  async (payload: any) => {
    const { workspaceId = '', start = '', end = '' } = payload;
    return await DashboardAPI.fetchTotalCV(workspaceId, start, end);
  }
);

const initialState: ICVSourced = {
  total: 0,
  loading: 'idle',
  error: '',
};

const cvSourceSlice = createSlice({
  name: 'cvSource',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchTotalCV.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchTotalCV.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.total = action.payload;
      })
      .addCase(fetchTotalCV.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'something went wrong!';
      }),
});

export default cvSourceSlice.reducer;

export { fetchTotalCV };
