import { axiosInstance } from '../../utils/axios.instance';

export const reqShareCandidateViaEmail = (data: any) => {
  return axiosInstance({
    method: 'POST',
    url: '/api/v1/job-application/share-via-email',
    data,
  });
};

export const reqSendEmailToCandidate = (data: any) => {
  return axiosInstance({
    method: 'POST',
    url: '/api/v1/job-application/send-email-to-candidate',
    data,
  });
};
