import { forwardRef, useEffect } from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import {
  toggleMenu,
  setOpenMenu,
} from '../../../../../redux/features/dashboard/layoutSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';

const NavItem = ({ item, level }: any) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
  const layout = useAppSelector((state) => state.layout);
  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon fontSize="1.2rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: layout.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: layout.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'small'}
    />
  );

  let listItemProps = {
    component: forwardRef((props: any, ref) => (
      <Link ref={ref} {...props} to={item.url} />
    )),
  };

  const itemHandler = (id: string) => {
    dispatch(setOpenMenu(id));
    if (matchesSM) dispatch(toggleMenu(false));
  };

  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);

    if (currentIndex > -1) {
      dispatch(setOpenMenu(item.id));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ListItemButton
      data-component-name="Sidebar"
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: '12px',
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: '3px',
        pl: `${level * 24}px`,
      }}
      selected={layout.isOpen.findIndex((id) => id === item.id) > -1}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon
        sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36, fontSize: '1.2rem' }}
      >
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body1" color="inherit">
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
    </ListItemButton>
  );
};

export default NavItem;
