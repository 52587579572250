import { lazy } from 'react';

import BaseLayout from '../components/layout/base-layout';
import SuspenseView from '../components/SuspenseView.react';

import LogoutPage from '../pages/Logout.page';
const LoginPage = lazy(() => import('../pages/login/Login.page'));
const RegisterPage = lazy(() => import('../pages/register/Register.page'));
const WorkspaceAccessPage = lazy(() => import('../pages/workspaceAccess.page'));

const ForgotPasswordPage = lazy(() => import('../components/forgot-password'));
const ResetPasswordPage = lazy(() => import('../components/reset-password'));
const SetPasswordPage = lazy(() => import('../components/set-password'));

const AuthenticationRoutes = {
  path: '/',
  element: <BaseLayout hideHeader />,
  children: [
    {
      path: '/login',
      element: (
        <SuspenseView>
          <LoginPage />
        </SuspenseView>
      ),
    },
    {
      path: '/register',
      element: (
        <SuspenseView>
          <RegisterPage />
        </SuspenseView>
      ),
    },
    {
      path: '/workspace-access',
      element: (
        <SuspenseView>
          <WorkspaceAccessPage />
        </SuspenseView>
      ),
    },
    {
      path: '/logout',
      element: <LogoutPage />,
    },
    {
      path: '/forgot-password',
      element: (
        <SuspenseView>
          <ForgotPasswordPage />
        </SuspenseView>
      ),
    },
    {
      path: '/reset-password',
      element: (
        <SuspenseView>
          <ResetPasswordPage />
        </SuspenseView>
      ),
    },
    {
      path: '/set-password',
      element: (
        <SuspenseView>
          <SetPasswordPage />
        </SuspenseView>
      ),
    },
  ],
};

export default AuthenticationRoutes;
