/**
 * ParseableTransport
 * Description: Axios based Transport to send event stream to Parseable server
 */

import { Buffer } from 'buffer';
import { AxiosRequestConfig } from 'axios';

import { parseableAxiosInstance } from '../utils/axios-parseable-instance';

const PARSEABLE_URL = 'https://parseable.technocube.in';
const BASIC_AUTH = 'Basic ' + Buffer.from('admin:admin@24').toString('base64');
const STREAM = 'atsfrontend';
const ERROR_STREAM = 'atserror';

const config: AxiosRequestConfig = {
  method: 'POST',
  baseURL: PARSEABLE_URL,
  url: `/api/v1/logstream/${STREAM}`,
  headers: {
    'X-P-META-Tag': 'hxl-rec',
    Authorization: BASIC_AUTH,
    'Content-Type': 'application/json',
  },
};

export default class ParseableTransport {
  static log(info: any) {
    // parseableAxiosInstance({
    //   ...config,
    //   url: `/api/v1/logstream/${ERROR_STREAM}`,
    //   data: {
    //     level: info.level || 'error',
    //     timestamp: new Date(),
    //     message: info.message,
    //     stack: info,
    //   },
    // })
    //   .then(function (response) {
    //     console.log(
    //       `Parseable logs sent with status with code ${response.status}`
    //     );
    //   })
    //   .catch(function (error) {
    //     console.log('axios error', error);
    //   });
  }

  static event(info: any) {
    // parseableAxiosInstance({
    //   ...config,
    //   data: {
    //     timestamp: new Date(),
    //     ...info,
    //   },
    // })
    //   .then(function (response) {
    //     console.log(
    //       `Parseable logs sent with status with code ${response.status}`
    //     );
    //   })
    //   .catch(function (error) {
    //     console.log('axios error', error);
    //   });
  }
}
