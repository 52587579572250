import { useState, useEffect, ChangeEvent, useCallback, useRef } from 'react';
import {
  TextField,
  Stack,
  Card,
  CardContent,
  Typography,
  FormControl,
  Button,
  Chip,
  Box,
  IconButton,
  FormHelperText,
  Autocomplete as MUIAutocomplete,
  Paper,
  CircularProgress,
  Modal,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import CloseIcon from '@mui/icons-material/Close';
import _, { isObject } from 'lodash';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import ReativeTime from 'dayjs/plugin/relativeTime';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';

import { validateEmail, generateTable } from '../../utils/helper-functions';
import Autocompelete from '../form-fields/Autocomplete.react';
import EmailBodyEditor from './EmailBodyEditor';
import { RootState } from '../../redux/store';
import { fetchTemplates } from '../../redux/features/mailer/mailer.slice';
import TemplatePopover from './TemplatePopover';
import FileServiceAPI from '../../redux/services/file.service';
import { jobStackholders } from '../../redux/features/jobs/jobPosting';
import GmailRecipientInput from './GmailRecipientInput';

dayjs.extend(ReativeTime);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const EmailComposer = (props: any) => {
  const [enableBcc, setEnableBcc] = useState(false);
  const [enableCC, setEnableCC] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [emailAttachement, setEmailAttachment] = useState<Array<any>>([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const { workspace } = useAppSelector((state) => state.app);
  const { selectedClient = {} } = useAppSelector((state) => state.client);
  const { templates } = useAppSelector((state: RootState) => state.mailer);

  const {
    attachment = [],
    recipientList = [],
    handleEmailSubmit,
    closeBtn = false,
    handleClose,
    loading = 'idle',
  } = props;

  const validateOnSubmit = (values: any) => {
    let error = false;
    if (values.recipients < 1) {
      setErrorMessage('Please specify at least one recipient.');
      error = true;
      return error;
    }
    if (values.message.length < 15) {
      error = true;
      setErrorMessage('message should be of atleast 15 characters');
      return error;
    }
    return error;
  };

  useEffect(() => {
    //@ts-ignore
    if (selectedClient.id && workspace.id) {
      dispatch(jobStackholders());
      dispatch(fetchTemplates({}));
    }
    //@ts-ignore
  }, [selectedClient.id, workspace.id]);

  useEffect(() => {
    if (attachment.length) {
      setEmailAttachment(attachment);
    }
  }, [attachment.length]);

  const feedbackTable = attachment
    .filter(
      (application: any) => application.feedback && application.feedback.length
    )
    .map((application: any) =>
      generateTable(application.feedback, application.name)
    )
    .join('');

  const handleChangeSearchText = (value: any) => {
    setSelectedTemplate(value);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const fileSizeLimit = 2 * 1024 * 1024; // 2 MB in bytes
      if (file.size > fileSizeLimit) {
        toast.error('File size exceeds the limit of 2 MB!', {
          hideProgressBar: true,
        });
        return;
      }
      const formData: any = new FormData();
      formData.append('file', file);
      formData.append('folder', 'event-attachements');
      setIsFileUploading(true);
      FileServiceAPI.uploadFileToS3(formData)
        .then((response) => {
          const { url = '' } = response.data;
          setEmailAttachment([
            ...emailAttachement,
            { name: file.name, s3url: url },
          ]);
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          setIsFileUploading(false);
        });
    }
  };
  const handleCcBccToggle = (btn: 'Cc' | 'Bcc') => {
    if (btn == 'Cc') {
      setEnableCC(true);
    }
    if (btn == 'Bcc') {
      setEnableBcc(true);
    }
  };

  const handleDeleteAttachment = (index: number) => {
    setEmailAttachment((prev: any) => {
      const updatedArray = [...prev];
      updatedArray.splice(index, 1);
      return updatedArray;
    });
  };

  console.log('recipientList', recipientList);

  return (
    <>
      <Modal open={Boolean(errorMessage)}>
        <Box
          component={Paper}
          py={3}
          px={2}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            overflow: 'hidden',

            zIndex: 999,
          }}
          gap={2}
        >
          <Typography variant="h3">Error</Typography>
          <Typography variant="body2">{errorMessage}</Typography>
          <Box width="100%" display="flex" justifyContent="flex-end" mt={2}>
            <Button variant="contained" onClick={() => setErrorMessage(null)}>
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          minWidth: 600,
          maxWidth: '65vw',
          overflow: 'scroll',
          bgcolor: 'background.paper',
          boxShadow: 24,
          zIndex: 999,
        }}
      >
        <Box
          component={Paper}
          elevation={5}
          sx={{
            width: '100%',
            minWidth: '400px',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            py={1}
            bgcolor="#f2f2f2"
          >
            <Typography variant="body2">New Message</Typography>
            {closeBtn && (
              <IconButton onClick={handleClose} size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          </Stack>

          <Formik
            enableReinitialize
            initialValues={{
              recipients: recipientList || [],
              subject: props.subject || '',
              bcc: [],
              cc: [],
              message: '',
            }}
            onSubmit={(values) => {
              if (!validateOnSubmit(values)) {
                handleEmailSubmit({ ...values, attachment: emailAttachement });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setTouched,
              isSubmitting,
            }) => {
              return (
                <Stack
                  direction={'column'}
                  alignItems={'flex-start'}
                  px={2}
                  pb={2}
                  overflow="auto"
                  maxHeight="80vh"
                  gap={1}
                >
                  <GmailRecipientInput
                    isCcVisible={enableCC}
                    isBccVisible={enableBcc}
                    toggleVisibility={handleCcBccToggle}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <FormControl fullWidth>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      borderBottom="1px solid #ddd"
                    >
                      <TextField
                        margin="dense"
                        name="subject"
                        size="small"
                        required
                        fullWidth
                        placeholder="Subject"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        sx={{
                          padding: 0,
                          '& .MuiOutlinedInput-root': {
                            padding: 0,
                            '& fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& input': {
                              padding: '0', // Adjust input padding if needed
                            },
                          },
                          '& .MuiInputBase-root': {
                            padding: 0,
                            '&:focus': {
                              outline: 'none',
                            },
                          },
                        }}
                        inputProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        value={values.subject}
                        error={touched.subject && Boolean(errors.subject)}
                      />
                    </Box>
                  </FormControl>
                  {templates?.data.length > 0 && (
                    <FormControl fullWidth>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        borderBottom="1px solid #ddd"
                      >
                        <MUIAutocomplete
                          options={templates?.data}
                          multiple={false}
                          fullWidth
                          value={selectedTemplate}
                          getOptionLabel={(option: any) => option.title}
                          renderOption={(props: any, option: any) => (
                            <Box component="li" {...props}>
                              <Stack direction="column">
                                <Typography>{option.title}</Typography>
                                <Typography variant="caption">
                                  {`${option?.user?.name} ${dayjs(
                                    option.createdAt
                                  ).fromNow()}`}
                                </Typography>
                              </Stack>
                            </Box>
                          )}
                          filterOptions={(options: any, state: any) => {
                            const displayOptions = options.filter(
                              (option: any) =>
                                option.title
                                  .toLowerCase()
                                  .trim()
                                  .includes(
                                    state.inputValue.toLowerCase().trim()
                                  )
                            );

                            return displayOptions;
                          }}
                          onChange={(e, value) => {
                            setFieldValue('message', value.content);
                            handleChangeSearchText(value);
                          }}
                          sx={{
                            paddingX: 0,

                            '& .MuiOutlinedInput-root': {
                              paddingLeft: '0px !important',
                              paddingRight: '0px !important',
                              '& input': {
                                paddingLeft: '0px !important',
                                paddingRight: '0px !important',
                                fontSize: '14px !important',
                              },
                              '& input::placeholder': {
                                fontSize: '14px !important',
                              },
                              '& fieldset': {
                                border: 'none',
                                paddingLeft: '0px !important',
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              placeholder="Type to search email templates"
                            />
                          )}
                        />
                      </Box>
                    </FormControl>
                  )}
                  <FormControl error={!!errors.message} fullWidth>
                    <EmailBodyEditor
                      value={values.message}
                      apiKey="03da2acumrl10xnzxdvuqiqxhdhlfrw7q846nw0fbh3k4tvs"
                      onChange={(data: any) => setFieldValue('message', data)}
                    />
                    {errors.message && errors.message && (
                      <FormHelperText error>
                        {errors.message.toString()}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {emailAttachement && emailAttachement.length > 0 && (
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      justifyContent="flex-start"
                      gap={1}
                      flexWrap="wrap"
                    >
                      <Typography
                        variant="subtitle2"
                        marginTop={2}
                        marginBottom={1}
                      >
                        Attachments
                      </Typography>
                      {emailAttachement.map(
                        (
                          { email, name }: { email: string; name: string },
                          index: number
                        ) => (
                          <Chip
                            icon={<FilePresentOutlinedIcon />}
                            label={email || name}
                            variant="filled"
                            color="primary"
                            size="small"
                            key={email}
                            sx={{
                              '.MuiChip-label': {
                                textTransform: 'lowercase',
                              },
                            }}
                            onDelete={() => handleDeleteAttachment(index)}
                          />
                        )
                      )}
                      {isFileUploading && <CircularProgress size={20} />}
                    </Stack>
                  )}
                  <Box
                    width={'97%'}
                    fontSize={'12px'}
                    dangerouslySetInnerHTML={{ __html: feedbackTable }}
                  />

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={1}
                  >
                    {/* @ts-ignore */}
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit}
                      loading={loading === 'pending'}
                    >
                      Send
                    </LoadingButton>
                    <TemplatePopover template={values.message} />
                    <IconButton
                      component="label"
                      role={undefined}
                      size="small"
                      tabIndex={-1}
                      color="primary"
                    >
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange}
                      />
                      <AttachFileIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                </Stack>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default EmailComposer;
