import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Typography,
  Stack,
  Box,
  Paper,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppSelector } from '../../../hooks/redux';

import SettingsGroupList from './SettingsGroupList';
import { drawerWidth } from '../../../utils/constants';
import { getUserWorkspaceClients } from '../../../redux/features/dashboard/clientSlice';

const workspaceOptions = [
  {
    title: 'General',
    path: 'settings',
  },
  // {
  //   title: 'Security',
  //   path: 'settings/not-found',
  // },
  {
    title: 'Members',
    path: 'settings/members',
  },
  {
    title: 'Integrations',
    path: 'settings/integrations',
  },
];

const accountOptions = [
  {
    title: 'Profile',
    path: 'settings/profile',
  },
  // {
  //   title: 'Preferences',
  //   path: 'settings/not-found',
  // },
  // {
  //   title: 'Notifications',
  //   path: 'settings/not-found',
  // },
  {
    title: 'Security & Access',
    path: 'settings/security-access',
  },
];

export default ({ drawerOpen, drawerToggle }: any) => {
  const [channelOptions, setChannelOptions] = useState<any>([]);
  const { permissions, email = '' } = useAppSelector((state) => state.app);
  const clients = useAppSelector(getUserWorkspaceClients);
  const { invt_member } = permissions;
  const theme = useTheme();
  const navigate = useNavigate();
  const handleBackward = () => {
    navigate('/');
  };

  useEffect(() => {
    if (clients.length) {
      const options = clients.map((client) => ({
        title: client.name,
        description: '',
        options: [
          {
            title: 'General',
            description: 'Manage general settings',
            path: 'settings/not-found',
          },
          {
            title: 'Members',
            description: 'Manage workspace members',
            path: 'settings/not-found',
          },
        ],
      }));
      setChannelOptions(options);
    }
  }, [clients]);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={drawerOpen}
      onClose={drawerToggle}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderRight: 'none',
          overflowY: 'hidden',
          '&:hover': {
            overflowY: 'auto',
          },
        },
      }}
    >
      <Box p={2} data-component-name="Settings" component={Paper}>
        <Stack direction="row" gap={1} alignItems="center">
          <IconButton size="small" onClick={handleBackward}>
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <Typography variant="h6">Settings</Typography>
        </Stack>
        {invt_member && email !== 'false' && (
          <SettingsGroupList title="Workspace" options={workspaceOptions} />
        )}
        <SettingsGroupList title="My Account" options={accountOptions} />
        {/* {invt_member && email !== 'false' && (
          <SettingsGroupList title="Channels" options={channelOptions} />
        )} */}
      </Box>
    </Drawer>
  );
};
