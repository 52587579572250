import React, { FC, useMemo, useState, useCallback } from 'react';

import { Typography, Box } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

interface Props {
  fileUrl: string | null;
}

const ResumeViewer: FC<Props> = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState<number>(0);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    },
    []
  );

  const Row = useCallback(
    ({ index, style }: ListChildComponentProps) => (
      <Box style={style} justifyContent="center" alignItems="center">
        <Page
          pageNumber={index + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          //   width={600}
          key={`page-${index + 1}`}
        />
      </Box>
    ),
    []
  );

  const renderContent = useMemo(() => {
    if (!fileUrl) {
      return (
        <Typography>No Resume has been found for this candidate!</Typography>
      );
    }

    return (
      <Document
        file={fileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        error="Failed to load file"
        loading="Loading..."
      >
        {numPages > 0 && (
          <List height={600} itemCount={numPages} itemSize={900} width="100%">
            {Row}
          </List>
        )}
      </Document>
    );
  }, [fileUrl, numPages, onDocumentLoadSuccess, Row]);

  return <Box>{renderContent}</Box>;
};

export default ResumeViewer;
