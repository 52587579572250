import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import TalentPoolAPIService from '../../services/talent-pool.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IState {
  loading: ILoading;
  error: string;
  data: any | null;
}

export const fetchApplicant = createAsyncThunk(
  'telentPoolApplicant/fetch-applicant',
  async (payload: { id: number }) => {
    return await TalentPoolAPIService.getApplicant(payload.id);
  }
);

const initialState: IState = {
  loading: 'idle',
  error: '',
  data: null,
};

const applicantSlice = createSlice({
  name: 'telentPoolApplicant',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = 'idle';
      state.data = null;
      state.error = '';
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchApplicant.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchApplicant.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchApplicant.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'something went wrong!';
      }),
});

export const { reset } = applicantSlice.actions;
export default applicantSlice.reducer;
