import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import DashboardAPI from '../../services/dashboard.service';

interface ICVDistribution {
  data: {
    job: Array<{ id: number; title: string; count: number }>;
    channel: Array<{ id: number; title: string; count: number }>;
    source: Array<{ source: string; count: number }>;
    users: Array<{ id: number | null; source: string; count: number }>;
  };
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
}

const fetchCVDistribution = createAsyncThunk(
  'cv-sourced/cv-distribution',
  async (payload: any): Promise<any> => {
    const { workspaceId = '', start = '', end = '' } = payload;
    return await DashboardAPI.fetchCVDistribution(workspaceId, start, end);
  }
);

const initialState: ICVDistribution = {
  data: {
    job: [],
    channel: [],
    source: [],
    users: [],
  },
  loading: 'idle',
  error: '',
};

const cvDistributionSlice = createSlice({
  name: 'cvSource',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchCVDistribution.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchCVDistribution.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchCVDistribution.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'something went wrong!';
      }),
});

export default cvDistributionSlice.reducer;

export { fetchCVDistribution };
