import { configureStore } from '@reduxjs/toolkit';

import loginReducer from './features/onboarding/loginSlice';
import registerReducer from './features/onboarding/registerSlice';

import dashboardReducer from './features/dashboard/dashboardSlice';
import appReducer from './common/appSlice';
import alertReducer from './features/dashboard/alertSlice';
import todoReducer from './features/dashboard/todoSlice';
import clientReducer from './features/dashboard/clientSlice';
import jobReducer from './features/jobs/jobSlice';
import applicationReducer from './features/applications/applicationSlice';
import jobPostingReducer from './features/jobs/jobPosting';
import uploadApplicantReducer from './features/applications/uploadApplicantSlice';
import layoutReducer from './features/dashboard/layoutSlice';
import applicantEmailReducer from './features/applications/emailSlice';
import hiringWorkflowReducer from './features/jobs/hiringWorkflow.slice';
import evaluationTemplateReducer from './features/jobs/evaluation-template.slice';
import notificationReducer from './features/notification/notification.slice';
import commentReducer from './features/comments/comment.slice';
import mailerReducer from './features/mailer/mailer.slice';
import talentPoolComment from './features/comments/talent-pool-comment.slice';
import report from './features/reports/reports.slice';
import integrations from './features/settings/integrationsSlice';
import dayplanner from './features/dashboard/day-planner.slice';
import recruiterActions from './features/candidate/recruiter-action.slice';
import candidateTimeline from './features/candidate/timeline.slice';
import talentPool from './features/talent-pool';
import dashboardReducerV2 from './features/dashboard';

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    login: loginReducer,
    register: registerReducer,
    app: appReducer,
    alert: alertReducer,
    todo: todoReducer,
    client: clientReducer,
    job: jobReducer,
    application: applicationReducer,
    jobPosting: jobPostingReducer,
    uploadApplicant: uploadApplicantReducer,
    layout: layoutReducer,
    applicantEmail: applicantEmailReducer,
    hiringWorkflow: hiringWorkflowReducer,
    evaluation: evaluationTemplateReducer,
    notification: notificationReducer,
    comment: commentReducer,
    mailer: mailerReducer,
    talentPoolComment,
    report,
    integrations,
    dayplanner,
    recruiterActions,
    candidateTimeline,
    talentPool,
    dashboard2: dashboardReducerV2,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
