import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import _ from 'lodash';

import NavGroup from './NavGroup';
import { filterMenuItemsOnPermissions } from '../../../../utils/helper-functions';

interface Props {
  menuItems: Array<any>;
  permissions: Record<string, string>;
}

const MenuList = ({ menuItems, permissions }: Props) => {
  const menuItem = menuItems[0];
  const [menu, setMenu] = useState<Array<any>>([]);
  useEffect(() => {
    if (permissions && !_.isEmpty(permissions)) {
      const filterMenuItems = filterMenuItemsOnPermissions(
        permissions,
        menuItem
      );
      if (filterMenuItems.children.length > 0) {
        setMenu([filterMenuItems]);
      }
    }
  }, [permissions]);

  const navItems = menu.map((item: any) => {
    if (item.type == 'group') {
      return <NavGroup key={item.id} item={item} />;
    }
    return (
      <Typography key={item.id} variant="h6" color="error" align="center">
        Menu Items Error
      </Typography>
    );
  });
  return <>{navItems}</>;
};

export default MenuList;
