import React, { useState } from 'react';
import {
  Box,
  TextField,
  Chip,
  Typography,
  Autocomplete,
  styled,
  Stack,
  Tooltip,
} from '@mui/material';

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const StyledChip = styled(Chip)({
  '& .MuiChip-label': {
    textTransform: 'lowercase',
  },
});

interface RecipientFieldProps {
  label: string;
  recipients: string[];
  setRecipients: React.Dispatch<React.SetStateAction<string[]>>;
}

interface CcAndBccButtonProps {
  showCcButton: boolean;
  showBccButton: boolean;
  onToggle: (type: 'Cc' | 'Bcc') => void;
}

const CcAndBccButton: React.FC<CcAndBccButtonProps> = ({
  showCcButton,
  showBccButton,
  onToggle,
}) => (
  <Stack direction="row" alignItems="center" gap={1}>
    {showCcButton && (
      <Tooltip title="Add Cc Recipients">
        <Typography
          variant="body2"
          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          onClick={() => onToggle('Cc')}
        >
          Cc
        </Typography>
      </Tooltip>
    )}
    {showBccButton && (
      <Tooltip title="Add Bcc Recipients">
        <Typography
          variant="body2"
          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          onClick={() => onToggle('Bcc')}
        >
          Bcc
        </Typography>
      </Tooltip>
    )}
  </Stack>
);

const RecipientField: React.FC<RecipientFieldProps> = ({
  label,
  recipients,
  setRecipients,
}) => {
  const handleAddRecipient = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const value = event.currentTarget?.value?.trim();
      if (value && !recipients.includes(value)) {
        setRecipients((prev) => [...prev, value]);
        if (event.currentTarget) {
          event.currentTarget.value = ''; // Clear the input field
        }
      }
      event.preventDefault();
    }
  };

  const handleDeleteRecipient = (email: string) => {
    const newRecipients = recipients.filter((recipient) => recipient !== email);
    setRecipients(newRecipients);
  };

  return (
    <Box display="flex" alignItems="center" flex={1}>
      <Typography variant="body2" sx={{ marginRight: 1 }}>
        {label}
      </Typography>
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        value={recipients}
        onChange={(event, newValue) => {
          const allValid = newValue.every((email) => emailRegex.test(email));
          if (allValid) {
            setRecipients(newValue);
          }
        }}
        renderTags={(value: string[], getTagProps) =>
          value.map((option, index) => (
            <StyledChip
              label={option}
              {...getTagProps({ index })}
              key={`${option}-${index}`}
              onDelete={() => handleDeleteRecipient(option)}
            />
          ))
        }
        size="small"
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
          },
        }}
        clearIcon={null}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            onKeyDown={handleAddRecipient}
          />
        )}
      />
    </Box>
  );
};

interface GmailRecipientInputProps {
  isCcVisible: boolean;
  isBccVisible: boolean;
  toggleVisibility: (type: 'Cc' | 'Bcc') => void;
  values: any;
  setFieldValue: any;
}

const GmailRecipientInput: React.FC<GmailRecipientInputProps> = ({
  isCcVisible,
  isBccVisible,
  toggleVisibility,
  values,
  setFieldValue,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      borderBottom="1px solid #ddd"
    >
      <Box display="flex" alignItems="center">
        <RecipientField
          label="To"
          recipients={values.recipients}
          setRecipients={(value) => setFieldValue('recipients', value)}
        />
        <CcAndBccButton
          showCcButton={!isCcVisible}
          showBccButton={!isBccVisible}
          onToggle={toggleVisibility}
        />
      </Box>
      {isCcVisible && (
        <RecipientField
          label="Cc"
          recipients={values.cc}
          setRecipients={(value) => setFieldValue('cc', value)}
        />
      )}
      {isBccVisible && (
        <RecipientField
          label="Bcc"
          recipients={values.bcc}
          setRecipients={(value) => setFieldValue('bcc', value)}
        />
      )}
    </Box>
  );
};

export default GmailRecipientInput;
