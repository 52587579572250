import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

import { fetchJobs } from '../../services/fetch-jobs';
import { fetchJobById, sendJobDescriptionEmail } from '../../services/jobs';
import { RootState } from '../../store';
import JobAPI from '../../services/job.service';
import { LoadingStatus } from '../../../types/app-state';

export interface AsyncState<T = any> {
  loading: LoadingStatus;
  error: string | null;
  data: T;
}

interface IJobstakeholder {
  id: number;
  name: string;
  email: string;
}

export interface IJobState {
  loading: LoadingStatus;
  error: string;
  list: Array<{}>;
  total: number;
  hiringworkflow: AsyncState;
  jobStakeholder: IJobstakeholder[];
  jobPosting: AsyncState;
  sendJD: AsyncState;
  updateStakeholder: Omit<AsyncState, 'data'>;
}

const initialState: IJobState = {
  loading: 'idle',
  error: '',
  list: [],
  total: 0,
  hiringworkflow: {
    loading: 'idle',
    error: '',
    data: [],
  },
  jobStakeholder: [],
  jobPosting: {
    loading: 'idle',
    error: '',
    data: {},
  },
  sendJD: {
    loading: 'idle',
    error: '',
    data: {},
  },
  updateStakeholder: {
    loading: 'idle',
    error: '',
  },
};

export const getJobHiringWorkflow = createAsyncThunk(
  'job/fetch-hiring-workflow',
  async ({ jobId }: { jobId: number }) => {
    return await JobAPI.hiringWorkflow(jobId);
  }
);

export const getJobsByPage = createAsyncThunk(
  'jobs/fetchJobs',
  async (
    {
      page,
      status,
      selfPosted,
    }: { page: number; status?: string; selfPosted?: boolean },
    { getState }
  ) => {
    const state = getState() as RootState;

    let basChannel: any = localStorage.getItem('baseChannel') || '';
    basChannel = JSON.parse(basChannel);
    const clientId = state.client?.selectedClient?.id || basChannel.id;
    //@ts-ignore
    const workspaceId = state.app.workspace.id;

    const response = await fetchJobs(
      page,
      workspaceId,
      clientId,
      status,
      selfPosted
    );
    return response.data;
  }
);

export const getWorkspaceJobsByPage = createAsyncThunk(
  'jobs/workspace-jobs',
  async (
    {
      page,
      status,
      selfPosted,
      searchText,
    }: {
      page: number;
      status?: string;
      selfPosted?: boolean;
      searchText?: string;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    //@ts-ignore
    const workspaceId = state.app.workspace.id;
    return await JobAPI.fetchRecruiterAssigned(
      Number(workspaceId),
      page,
      selfPosted,
      status,
      searchText
    );
  }
);

export const getJobById = createAsyncThunk(
  'job/fetchJobById',
  async (jobId: number) => {
    const response = await fetchJobById(jobId);
    return response.data;
  }
);

export const sendJobDescription = createAsyncThunk(
  'job/sendJobDescription',
  async (data: any) => {
    const response = await sendJobDescriptionEmail(data);
    return response.data;
  }
);

export const addStakeholder = createAsyncThunk(
  'job/updateStakeholder',
  async (
    stakeholder: Array<{ recruiterId: number; jobId: number }>,
    { dispatch }
  ) => {
    const response = await JobAPI.addStakeholdersToJob(stakeholder);
    dispatch(getJobById(stakeholder[0].jobId));
    return response;
  }
);

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    resetHiringWorkflow: (state) => {
      state.hiringworkflow = { loading: 'idle', error: '', data: [] };
    },
    resetJob: (state) => {
      state.jobPosting.data = {};
    },
    resetJobList: (state) => {
      state.list = [];
    },
    resetSendJD: (state) => {
      state.sendJD.loading = 'idle';
      state.sendJD.data = {};
      state.sendJD.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobsByPage.pending, (state) => {
        return {
          ...state,
          loading: 'pending',
        };
      })
      .addCase(getJobsByPage.fulfilled, (state, action) => {
        return {
          ...state,
          loading: 'succeeded',
          list: action.payload.jobs,
          total: action.payload.total,
        };
      })
      .addCase(getJobsByPage.rejected, (state, action) => {
        return {
          ...state,
          loading: 'failed',
        };
      })
      .addCase(getWorkspaceJobsByPage.pending, (state) => {
        return {
          ...state,
          loading: 'pending',
        };
      })
      .addCase(getWorkspaceJobsByPage.fulfilled, (state, action: any) => {
        return {
          ...state,
          loading: 'succeeded',
          list: action.payload.jobs,
          total: action.payload.total,
        };
      })
      .addCase(getWorkspaceJobsByPage.rejected, (state, action) => {
        return {
          ...state,
          loading: 'failed',
        };
      })
      .addCase(getJobById.pending, (state) => {
        state.jobPosting.loading = 'pending';
      })
      .addCase(getJobById.fulfilled, (state, action) => {
        state.jobPosting.data = action.payload;
        state.jobPosting.loading = 'succeeded';
      })
      .addCase(getJobById.rejected, (state) => {
        state.jobPosting.loading = 'failed';
        state.jobPosting.error = 'Something went wrong!';
      })
      .addCase(sendJobDescription.pending, (state) => {
        state.sendJD.loading = 'pending';
      })
      .addCase(sendJobDescription.fulfilled, (state, action) => {
        state.sendJD.data = action.payload;
        state.sendJD.loading = 'succeeded';
      })
      .addCase(sendJobDescription.rejected, (state) => {
        state.sendJD.loading = 'failed';
        state.sendJD.error = 'Something went wrong!';
      })
      .addCase(addStakeholder.pending, (state) => {
        state.updateStakeholder.loading = 'pending';
      })
      .addCase(addStakeholder.fulfilled, (state, action) => {
        state.updateStakeholder.loading = 'succeeded';
      })
      .addCase(addStakeholder.rejected, (state) => {
        state.updateStakeholder.loading = 'failed';
        state.updateStakeholder.error = 'Something went wrong!';
      })
      .addCase(getJobHiringWorkflow.pending, (state) => {
        state.hiringworkflow.loading = 'pending';
        state.hiringworkflow.error = '';
      })
      .addCase(getJobHiringWorkflow.fulfilled, (state, action) => {
        state.hiringworkflow.loading = 'succeeded';
        state.hiringworkflow.error = '';
        state.hiringworkflow.data = action.payload;
      })
      .addCase(getJobHiringWorkflow.rejected, (state, action) => {
        state.hiringworkflow.loading = 'failed';
        state.hiringworkflow.error =
          action.error.message || 'something went wrong!';
        state.hiringworkflow.data = [];
      });
  },
});

export const { resetJob, resetSendJD, resetHiringWorkflow, resetJobList } =
  jobSlice.actions;

export const getJobHiringStates = createSelector(
  [(state: RootState) => state.job.hiringworkflow],
  (hiringworkflow) => {
    const { loading, data } = hiringworkflow;
    if (loading === 'succeeded') {
      return data.flatMap(({ stage }: any) => ({
        label: `${stage.name}`,
        value: stage.id,
      }));
    }
    return [];
  }
);

export default jobSlice.reducer;
