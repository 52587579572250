import React, { ChangeEvent, useState, useRef } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  Modal,
  Box,
  FormControl,
  TextField,
  Stack,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  FormHelperText,
  createFilterOptions,
  Autocomplete,
  Switch,
  styled,
  CircularProgress,
  Chip,
} from '@mui/material';
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify';
import FileServiceAPI from '../../redux/services/file.service';

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const filter = createFilterOptions<any>();
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export interface Props {
  handleClose: any;
  open: boolean;
  onAddEvent: any;
  event?: any;
  onClickDelete?: any;
  onClickEdit?: any;
  isLoading?: any;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const style = {
  position: 'absolute',
  top: '20%',
  left: 0,
  right: 0,
  //   transform: "translate(-50%, -50%)",
  margin: '0 auto',
  width: 450,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 1,
};

const AddEventModal = (props: Props) => {
  const { handleClose, open, onAddEvent, event, onClickDelete, onClickEdit } =
    props;
  const {
    title = '',
    id = '',
    description = '',
    attendees = [],
    attachments = [],
    start = dayjs(),
    startTime = '',
    googleEventId = '',
  }: any = event?.extendedProps?.raw || {};
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [emailAttachement, setEmailAttachment] =
    useState<Array<any>>(attachments);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const fileSizeLimit = 2 * 1024 * 1024; // 2 MB in bytes
      if (file.size > fileSizeLimit) {
        toast.error('File size exceeds the limit of 2 MB!', {
          hideProgressBar: true,
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }
      const formData: any = new FormData();
      formData.append('file', file);
      formData.append('folder', 'event-attachements');
      setIsFileUploading(true);
      FileServiceAPI.uploadFileToS3(formData)
        .then((response) => {
          const { url = '' } = response.data;
          setEmailAttachment([
            ...emailAttachement,
            { title: file.name, fileUrl: url },
          ]);
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          setIsFileUploading(false);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        });
    }
  };

  const handleDeleteAttachment = (index: number) => {
    setEmailAttachment((prev: any) => {
      const updatedArray = [...prev];
      updatedArray.splice(index, 1);
      return updatedArray;
    });
  };

  return (
    <Modal open={open} onClose={handleClose} sx={{ overflow: 'scroll' }}>
      <Formik
        initialValues={{
          title,
          description,
          date: start ? dayjs(start) : dayjs(),
          time: start ? dayjs(start) : dayjs().add(10, 'minute'),
          duration: '15',
          googleConfrence: false,
          attendees: attendees ? attendees.map((user: any) => user.email) : [],
          attachments: [],
        }}
        onSubmit={(values) => {
          if (title) {
            onClickEdit({
              id,
              title: values.title,
              description: values.description,
              date: dayjs(values.date).format('YYYY-MM-DD'),
              startTime: dayjs(values.time).format('hh:mm A'),
              duration: values.duration,
              attendees: values.attendees,
              googleEventId: googleEventId || null,
              googleConfrence: values.googleConfrence,
              attachments: emailAttachement,
            });
          } else {
            onAddEvent({
              title: values.title,
              description: values.description,
              date: dayjs(values.date).format('YYYY-MM-DD'),
              startTime: dayjs(values.time).format('hh:mm A'),
              duration: values.duration,
              attendees: values.attendees,
              googleConfrence: values.googleConfrence,
              attachments: emailAttachement,
            });
          }
        }}
        validate={(values) => {
          const errors: { title?: string; duration?: string; date?: string } =
            {};
          if (!values.title) {
            errors.title = 'Please enter a event title!';
          }
          if (!values.duration) {
            errors.duration = 'Please select duration!';
          }
          if (!values.date) {
            errors.date = 'Please select event date!';
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Box sx={style} component="form" onSubmit={handleSubmit}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h5">Add event</Typography>

                <Box>
                  <IconButton onClick={onClickDelete}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Stack>
              <Stack direction={'column'} spacing={2}>
                <FormControl>
                  <TextField
                    margin="normal"
                    size="small"
                    fullWidth
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.title && Boolean(errors.title)}
                    label="Event Title"
                    placeholder="Event Title"
                    //@ts-ignore
                    helperText={touched.title && errors.title}
                  />
                </FormControl>
                <Stack direction="row" spacing={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Event Date"
                      format="DD-MM-YYYY"
                      value={values.date}
                      timezone="Asia/Calcutta"
                      disablePast
                      onChange={(date) => setFieldValue('date', date)}
                      slotProps={{
                        textField: {
                          size: 'small',
                          name: 'date',
                          error: touched.date && Boolean(errors.date),
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Event Time"
                      format="hh:mm A"
                      value={values.time}
                      timezone="Asia/Calcutta"
                      onChange={(time) => {
                        setFieldValue('time', time);
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          name: 'time',
                          error: touched.time && Boolean(errors.time),
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <FormControl
                    sx={{ minWidth: 120 }}
                    size="small"
                    error={touched.duration && Boolean(errors.duration)}
                  >
                    <InputLabel id="event-duration-input-label">
                      Duration
                    </InputLabel>
                    <Select
                      labelId="event-duration-input-label"
                      id="event-duration-input-select"
                      label="Duration"
                      size="small"
                      name="duration"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.duration}
                    >
                      <MenuItem value={5}>5 Mins</MenuItem>
                      <MenuItem value={10}>10 Mins</MenuItem>
                      <MenuItem value={15}>15 Mins</MenuItem>
                      <MenuItem value={20}>20 Mins</MenuItem>
                      <MenuItem value={25}>25 Mins</MenuItem>
                      <MenuItem value={30}>30 Mins</MenuItem>
                    </Select>
                    {touched.duration && Boolean(errors.duration) && (
                      <FormHelperText error>{errors.duration}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
                <FormControl>
                  <TextField
                    margin="normal"
                    size="small"
                    fullWidth
                    name="description"
                    multiline
                    rows={3}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    label="Description"
                    placeholder="Description"
                    //@ts-ignore
                    helperText={touched.description && errors.description}
                  />
                </FormControl>
                <FormControl>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Switch
                      name="googleConfrence"
                      color="secondary"
                      checked={values.googleConfrence}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFieldValue('googleConfrence', event.target.checked);
                      }}
                    />
                    <VideocamOutlinedIcon />
                    <Typography>Google Meet Video Confrence</Typography>
                    <IconButton
                      color="primary"
                      component="label"
                      role={undefined}
                      size="small"
                      tabIndex={-1}
                    >
                      <VisuallyHiddenInput
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                      <AttachFileIcon />
                    </IconButton>
                  </Stack>
                </FormControl>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={1}
                >
                  {emailAttachement.map(
                    ({ title }: { title: string }, index: number) => (
                      <Chip
                        icon={<FilePresentOutlinedIcon />}
                        label={title}
                        variant="filled"
                        color="primary"
                        size="small"
                        key={title}
                        sx={{
                          '.MuiChip-label': {
                            textTransform: 'lowercase',
                          },
                        }}
                        onDelete={() => handleDeleteAttachment(index)}
                      />
                    )
                  )}
                  {isFileUploading && <CircularProgress size={20} />}
                </Stack>
                <FormControl>
                  <Autocomplete
                    multiple
                    value={values.attendees}
                    options={[]}
                    onChange={(e: any, value: any) => {
                      const allValid = value.every((email: string) =>
                        emailRegex.test(email)
                      );
                      if (allValid) {
                        setFieldValue('attendees', value);
                      }
                    }}
                    handleHomeEndKeys
                    freeSolo
                    filterOptions={(options: any, params: any) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option: any) => inputValue === option
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push(inputValue);
                      }
                      return filtered;
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Attendees"
                        label="Attendees"
                      />
                    )}
                  />
                </FormControl>

                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={props.isLoading}
                  >
                    {title ? 'Edit' : 'Add'}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    disabled={props.isLoading}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Box>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddEventModal;
