import React, { useMemo, useCallback, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ToastContainer } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  setUploadFiles,
  setUploadProgress,
} from '../../redux/features/applications/uploadApplicantSlice';
import { axiosInstance } from '../../utils/axios.instance';
import { formatFileSize } from '../../utils/helper-functions';

const FILE_ACCEPT = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
};

const handleFileUpload = (
  file: File,
  dispatch: React.Dispatch<any>,
  jobId?: any
) => {
  const formData = new FormData();
  formData.append('file', file);
  if (jobId) {
    formData.append('jobId', jobId);
  }
  const url = jobId
    ? '/api/v1/job-application/upload-stream'
    : '/api/v1/talent-pool/upload-resume';

  axiosInstance({
    method: 'POST',
    url,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      dispatch(
        setUploadProgress({ file: file.name, progress: `${percentCompleted}%` })
      );
    },
  })
    .then((data) => {
      dispatch(setUploadProgress({ file: file.name, progress: 'uploaded' }));
    })
    .catch((error) => {
      console.log('error:FileUpload', error);
      dispatch(setUploadProgress({ file: file.name, progress: 'error' }));
    });
};

const FileQues: any = [];

const UploadFiles = (props: any) => {
  const [uploadFilesQueue, setUploadFilesQueue] = useState(FileQues);
  const dispatch = useAppDispatch();
  const {
    candidateUploadCvs = [],
    progress: {},
  } = useAppSelector((state) => state.uploadApplicant);
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const newFiles = acceptedFiles.filter(
        (file: any) =>
          !candidateUploadCvs.some(
            (droppedFile: any) => droppedFile.name === file.name
          )
      );
      const objFiles = newFiles.map((file: File) => {
        return {
          name: file.name,
          size: file.size,
          status: 'uploading',
        };
      });
      dispatch(setUploadFiles(objFiles));
      setUploadFilesQueue([...uploadFilesQueue, ...newFiles]);
      newFiles.forEach((file: File) => {
        handleFileUpload(file, dispatch, props?.job?.id);
      });
    },
    [candidateUploadCvs, dispatch]
  );

  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 100,
    accept: FILE_ACCEPT,
  });

  const handleFileRetry = (e: any, name: string) => {
    e.stopPropagation();
    const file = uploadFilesQueue.find((file: File) => file.name == name);
    handleFileUpload(file, dispatch, props?.job?.id);
  };

  const columns = useMemo<any>(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'File',
      },
      {
        accessorKey: 'size',
        header: 'Size',
        size: 100,
        Cell: ({ cell }: any) => {
          return formatFileSize(cell.getValue());
        },
      },
      {
        accessorKey: 'status', //normal accessorKey
        header: 'Status',
        Cell: ({ cell, row }: any) => {
          const { name } = row.original;
          return cell.getValue() == 'error' ? (
            <Button onClick={(e) => handleFileRetry(e, name)}>Retry</Button>
          ) : (
            <span>{cell.getValue()}</span>
          );
        },
      },
    ],
    [handleFileRetry]
  );

  return (
    <>
      <ToastContainer />
      <Box display={'flex'} {...getRootProps()} flexDirection={'column'}>
        <Box
          sx={{
            border: '2px dashed #eee',
            backgroundColor: isDragActive ? '#DDDDDD' : '#f5f7fb',
            minHeight: 250,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            my: 2,
          }}
        >
          <input {...getRootProps()} style={{ visibility: 'hidden' }} />
          <Typography variant="body1">
            {' '}
            Drag & Drop your file(s) here
          </Typography>
          <Typography variant="body1">OR</Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              my: 1,
            }}
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Select a file(s) to upload
          </Button>
          <Typography variant="caption">
            Only accept pdf, doc, docx, and odt file(s)
          </Typography>
        </Box>
        <MaterialReactTable
          initialState={{
            density: 'compact',
            showGlobalFilter: true,
            expanded: true,
          }}
          columns={columns}
          data={candidateUploadCvs}
          enableRowNumbers
          enableDensityToggle={false}
          enablePagination={false}
          enableTopToolbar={false}
          enableColumnFilterModes={false}
          enableColumnActions={false}
        />
      </Box>
    </>
  );
};

export default UploadFiles;
