import React, { FC, useState, useCallback, ChangeEvent } from 'react';
import _ from 'lodash';
import {
  Button,
  Popover,
  Box,
  FormControl,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
} from '@mui/material';
import { Formik, FormikValues } from 'formik';

import { useAppDispatch } from '../../hooks/redux';

import {
  fetchTemplates,
  createTemplate,
} from '../../redux/features/mailer/mailer.slice';

interface TemplatePopoverProps {
  template: string;
}

const TemplatePopover: FC<TemplatePopoverProps> = ({ template }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useAppDispatch();

  const open = Boolean(anchorEl);
  const id = open ? 'save-email-template' : undefined;

  const handleSaveTemplateClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTemplateOption = () => {
    setAnchorEl(null);
  };

  const handleSaveTemplate = (values: FormikValues) => {
    if (values.title && values.visibility) {
      dispatch(
        createTemplate({
          title: values.title,
          visibility: values.visibility,
          content: template,
        })
      );
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Button
        aria-describedby={id}
        disabled={!template}
        onClick={handleSaveTemplateClick}
      >
        Save As Template
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseTemplateOption}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Formik
          initialValues={{
            title: '',
            visibility: 'private',
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.title) {
              errors.title = 'Template title is required!';
            }
            return errors;
          }}
          onSubmit={handleSaveTemplate}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Box
              p={1}
              display="flex"
              flexDirection="column"
              minWidth={300}
              component="form"
              onSubmit={handleSubmit}
            >
              <FormControl error={touched.title && Boolean(errors.title)}>
                <FormLabel>Template Name</FormLabel>
                <TextField
                  placeholder="Enter Template Name"
                  size="small"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title && Boolean(errors.title)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Make this template visible to</FormLabel>
                <RadioGroup
                  defaultValue="private"
                  name="visibility"
                  value={values.visibility}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="only me"
                  />
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Anyone in my organization"
                  />
                </RadioGroup>
              </FormControl>
              <Stack direction="row" gap={1}>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  onClick={handleSaveTemplate}
                >
                  Save
                </Button>
                <Button size="small" onClick={handleCloseTemplateOption}>
                  Cancel
                </Button>
              </Stack>
            </Box>
          )}
        </Formik>
      </Popover>
    </>
  );
};

export default TemplatePopover;
